import React, {Fragment} from 'react';

import {TechseeBaseComponent} from '@techsee/techsee-ui-common/lib/_shared/techsee-base-component';
import DraggblePanel from '@techsee/techsee-ui-common/lib/draggble-panel';
import {observer} from 'mobx-react';
import {Icon} from '@techsee/techsee-ui-common/lib/icons/simple-icon';

import {IWifiAnalyzerController} from './controller';
import {ITab, SimpleTabs} from '@techsee/techsee-ui-common/lib/tabs/simple';
import {NetworkTab} from './components/NetworkTab';
import {ConnectedDevicesTab} from './components/ConnectedDevicesTab';
import './styles.scss';
import {ChannelsTab} from './components/ChannelsTab';
import {IconButton} from '@techsee/techsee-ui-common/lib/forms/buttons/icon';
import {Spinner, SpinnerBackgroundColors, SpinnerColors} from '@techsee/techsee-ui-common/lib/spinner';
import isEmpty from 'lodash/isEmpty';

interface WifiAnalyzerProps {
    ctrl: IWifiAnalyzerController;
}

@observer
export class WifiAnalyzer extends TechseeBaseComponent<WifiAnalyzerProps> {
    renderPanel(name: string, createdOn: Date, children: JSX.Element): JSX.Element {
        const {
            ctrl: {offset, position, isMinimized, deviceCategory, getFormattedTime, maximize, minimize, hide}
        } = this.props;

        return (
            <DraggblePanel
                className='use-common-styles'
                bounds={offset}
                position={position}
                wrapperElementClass='dashboard-main-container'
                offset={offset}
                disabled={isMinimized}
                deviceCategory={deviceCategory}
                handle={'.wifi-analyzer-header'}>
                <div className={`wifi-analyzer ${isMinimized ? 'minimized' : ''}`}>
                    <div className='wifi-analyzer-header'>
                        <div className='wifi-analyzer-header-left wifi-analyzer-click-handle'>
                            <h1>{name}</h1>
                            <span className='wifi-analyzer-header-time'>
                                <Icon iconName='clock' />
                                {getFormattedTime(createdOn?.toISOString() || new Date().toISOString())}
                            </span>
                        </div>
                        <div className='wifi-analyzer-icons'>
                            <IconButton
                                className='wifi-analyzer-button'
                                iconName={isMinimized ? 'maximize' : 'minimize'}
                                onClick={() => (isMinimized ? maximize() : minimize())}
                            />
                            <span className='close-button' onClick={() => hide()}>
                                <Icon
                                    className='wifi-analyzer-button'
                                    iconName={'close'}
                                    style={{fontSize: '24px', cursor: 'pointer'}}
                                />
                            </span>
                        </div>
                    </div>
                    {children}
                </div>
            </DraggblePanel>
        );
    }

    renderInternal(): JSX.Element {
        const {
            ctrl: {results, isScanning, isVisible, startScan, channelAnalysis, currentTab, setCurrentTab}
        } = this.props;

        if (!isVisible) {
            return <Fragment />;
        }

        if (!results) {
            return this.renderPanel(
                'Loading...',
                new Date(),
                <div className='wifi-analyzer-loader'>
                    <Spinner backgroundColorName={SpinnerBackgroundColors.Transparent} colorName={SpinnerColors.Blue} />
                    <span>WiFi Scan in Progress</span>
                </div>
            );
        }

        const tabs: ITab[] = [
            {
                title: 'NETWORK LIST',
                content: () => results && <NetworkTab results={results} />
            },
            {
                title: 'CHANNEL ANALYSIS',
                content: () =>
                    results && (
                        <ChannelsTab
                            platformType={results.platformType}
                            channelAnalysis={channelAnalysis}
                            isScanning={isScanning}
                            onStartScan={startScan}
                        />
                    )
            }
        ];

        if (!isEmpty(results.networkDiscoveryDevices)) {
            tabs.push({
                title: 'CONNECTED DEVICES',
                content: () => <ConnectedDevicesTab networkDiscoveryDevices={results.networkDiscoveryDevices} />
            });
        }

        return this.renderPanel(
            results.name,
            results.createdOn,
            <div className='wifi-analyzer-simple-tabs'>
                <SimpleTabs
                    uid='wifi-analyzer-tabs'
                    tabsList={tabs}
                    initialTabIndex={currentTab}
                    onChange={setCurrentTab}
                />
            </div>
        );
    }
}
